<template>
    <h1>龙骨动画服务条款</h1>
    <p><strong>更新日期：2025年2月2日</strong></p>

    <h2>欢迎来到龙骨动画！</h2>
    <p>
        龙骨动画是由北京哪吒互娱科技有限公司（下称“哪吒互娱”）提供的互联网软件服务。本服务条款（下称“服务条款”）是您与哪吒互娱之间关于您（“您”或“用户”）访问和使用龙骨动画以及哪吒互娱提供的其他服务（下称“服务”）的主要协议。您注册、登录龙骨动画和/或使用哪吒互娱提供的服务，即表示您同意接受服务条款，如您不同意该协议，请不要使用本服务。因此，敬请仔细阅读。
    </p>

    <h2>1. 服务内容</h2>
    <p>
        龙骨动画的具体服务由哪吒互娱根据实际情况提供，例如、个人信息分享以及在线评论、在线协作等服务。哪吒互娱为用户提供了专业易用的动画工具和海量图库资源和动画资源，支持在线创作骨骼动画，序列帧动画，图片，gif，视频，html等，同时依托于互联网实现了用户间的实时协作和共享。
    </p>

    <h2>2. 注册</h2>
    <p>
        为了能访问龙骨动画和使用服务，您同意以下事项：依龙骨动画网站提示填写准确的、真实的注册手机号、邮箱、密码和名称，并确保今后更新的登录手机号、邮箱、名称、头像等资料的有效性和合法性。若您提供任何违法、虚假、不道德或哪吒互娱认为不适合在龙骨动画上展示的资料；或者哪吒互娱有理由怀疑您的资料属于病毒程序或恶意操作；或者您违反本服务条款的规定；或者未经哪吒互娱同意，将龙骨动画用于商业目的，哪吒互娱有权暂停或终止您的帐号，并拒绝您于现在和未来使用服务之全部或任何部分。
    </p>

    <h2>3. 会员服务</h2>
    <p>
        龙骨动画会员服务（“会员服务”）是您支付会员服务费后在会员有效时长内享有包括“不限文件数量”等服务在内的服务产品。您可以选择购买多种类型龙骨动画的会员服务，在依约支付一定费用后开通会员服务。会员服务开通成功后，您可在会员有效时长内享受相应会员权益。哪吒互娱会根据用户的需求、产品的变化等，对现有会员服务内容进行调整。哪吒互娱也会在不同时间推出具体不同的会员服务内容，以不断完善、优化会员服务。具体会员服务内容以【“价格”】页面公布、实际提供的内容为准。
    </p>

    <h2>4. 内容发布以及使用权</h2>
    <h3>4.1 内容的发布</h3>
    <p>
        用户在龙骨动画上发布的内容（包含但不限于龙骨动画目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表哪吒互娱的立场或观点。作为内容的发布者，您对在本平台上传、发布的内容承担全部责任，与哪吒互娱无关。哪吒互娱无法控制经由本平台上传、发布的内容，因此不保证上传、发布的内容的正确性、合法性、安全性、完整性。尽管哪吒互娱有权对您上传至龙骨动画的数字作品进行审核，但该审核仅限于形式审核，不对您上传的内容作任何实质性审核，且哪吒互娱的上述审核行为不视为哪吒互娱对于您使用本平台所上传、发布的内容承担任何保证责任。为了保证发布模板的规范性和准确性，哪吒互娱有权对发布至模板社区的模板进行调整（比如修改标题、标签、文件描述、外观样式等）。一旦发布的模板涉嫌侵权或违法违规内容，哪吒互娱有权下架模板，并且视情节轻重对该账号采取限制发布、冻结收益、注销账号等措施。如果涉嫌侵权或违法违规行为的用户是VIP，哪吒互娱有权取消其VIP身份。如您使用本平台上传的内容违反法律规定或侵害到其他人合法权益导致任何第三人提出索赔的，均由您独立承担全部法律责任。
    </p>

    <h3>4.2 内容的使用权</h3>
    <p>
        用户将文件发布并公开到龙骨动画后，即视为永久授权龙骨动画使用，并授权哪吒互娱将文件通过互联网及/或移动互联网面向全球用户进行发布、推广以及传播，以及以任何合法方式将文件用于对龙骨动画平台的宣传、推广。若用户上传的文件涉及到个人隐私及商业秘密应在文件中予以注明，对于未注明的，视为未涉及个人隐私及商业秘密，龙骨动画可以不做任何标记直接用于宣传及推广展示使用，若因此发生纠纷，龙骨动画不承担任何法律责任。
    </p>

    <h2>5. 版权</h2>
    <p>龙骨动画的外观设计、计算机代码与专利等均归哪吒互娱所有。未经哪吒互娱事先书面许可，您不能复制、拷贝、或者使用任何部分的代码和外观设计。</p>

    <h2>6. 隐私政策</h2>
    <p>使用龙骨动画和哪吒互娱提供的服务，即表示您同意哪吒互娱合法收集和使用有关您及您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进龙骨动画产品的内容和技术，提升龙骨动画的服务品质。</p>

    <h2>7. 数据安全</h2>
    <p>
        哪吒互娱将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。
    </p>

    <h2>8. 免责声明</h2>
    <p>一旦您注册成为用户即表示您与哪吒互娱达成协议，完全接受本服务条款项下的全部条款。对免责声明的解释、修改及更新权均属于哪吒互娱所有。</p>
    <ul>
        <li>由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因哪吒互娱原因导致的个人信息的泄漏，哪吒互娱不承担任何法律责任；</li>
        <li>任何第三方根据哪吒互娱各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷，哪吒互娱不承担任何法律责任以及连带责任；</li>
        <li>任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭，哪吒互娱不承担任何法律责任；</li>
        <li>
            我们鼓励用户充分利用龙骨动画自由地发布和共享自己的信息。您可以自由发布文字、图片、动画、视频等内容，但这些内容必须位于公共领域内，或者您拥有这些内容的使用权。同时，用户不应在自己的个人主页或龙骨动画的任何其他地方发布受版权保护的内容；
        </li>
        <li>用户在龙骨动画发布侵犯他人知识产权或其他合法权益的内容，哪吒互娱有权予以删除，并保留移交司法机关处理的权利；</li>
        <li>用户对于自己创作并在龙骨动画上发布的合法作品依法享有著作权及其他相关权利；</li>
        <li>
            互联网是一个开放平台，用户将图片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。用户明确同意使用哪吒互娱服务所存在的风险将完全由用户自己承担；因用户使用哪吒互娱服务而产生的一切后果也由用户自己承担，哪吒互娱对用户不承担任何责任。
        </li>
    </ul>

    <h2>9. 服务变更/中断/终止</h2>
    <p>如因系统维护或升级的需要而暂停网络服务、调整服务功能的，哪吒互娱将尽可能事先在网站上进行通告。</p>
    <p>如发生下列任何一种情形，哪吒互娱有权单方面中断或终止向用户提供服务而无需通知用户：</p>
    <ul>
        <li>用户提供的个人资料不真实；</li>
        <li>用户违反本服务条款中规定的使用规则；</li>
        <li>未经哪吒互娱同意，将龙骨动画用于商业目的。</li>
        <li>
            禁止在未经预先书面授权的情况下销售或转卖哪吒互娱的付费服务（包括但不限于会员权益和文件扩容数）。如果发现存在未经哪吒互娱预先书面授权囤积、倒卖、转售哪吒互娱产品的行为，哪吒互娱有权追究其的法律责任。同时，哪吒互娱有权调查任何通过不正当手段获取，会员权益、文件扩容数及兑换码的行为，一经发现，哪吒互娱有权收回用户已获得的奖励、权益，情节严重的，哪吒互娱将冻结其账号或者封号，哪吒互娱有权采用以上一种或多种的方式对违规用户进行处理。
        </li>
    </ul>

    <h2>10. 退款政策</h2>
    <p>龙骨动画网站所售会员服务属于虚拟产品，一经生效后不支持退款，感谢您的理解与支持。</p>

    <h2>11. 注销账号</h2>
    <p>您有权通过注销账号的方式终止本协议，您可以通过邮箱【<a
            href="mailto:nezhahuyu@126.com">nezhahuyu@126.com</a>】申请完成账号注销。账号一旦注销将不可恢复，请您在操作之前自行备份账号相关的所有信息和内容，账号注销后，您将无法继续使用该账号，也无法找回该账号中的任何内容或信息，除法律有明确规定外，哪吒互娱无义务向您或您指定的第三方披露您账号中的任何内容或信息。
    </p>

    <h2>12. 服务条款的完善和修改</h2>
    <p>
        哪吒互娱有权根据互联网行业变化以及有关法律、法规的颁布、修订，不时地完善和修改哪吒互娱服务条款。哪吒互娱保留随时修改服务条款的权利，用户在使用哪吒互娱的服务时，有必要对哪吒互娱最新的服务条款进行仔细阅读和重新确认，当发生相关争议时，以最新的服务条款为准。
    </p>

    <h2>13. 特别约定</h2>
    <p>本服务条款及其下的服务受中华人民共和国法律管辖，并按之解释。</p>
    <p>用户使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方合法权益的情形，哪吒互娱有权直接删除该等违反规定的信息，并可以暂停或终止向该用户提供服务。</p>
    <p>若用户利用本服务从事任何违法或侵权行为，由用户自行承担全部责任，哪吒互娱不承担任何法律责任及连带责任。因此给哪吒互娱或任何第三方造成任何损失的，用户应负责全额赔偿。</p>
    <p>用户在此特别声明并承诺，用户已充分注意本服务协议内免除或限制哪吒互娱责任的条款，用户完全知晓并理解该等条款的规定并同意接受。</p>

    <h2>14. 联系我们</h2>
    <p>欢迎您对我们的服务条款提出意见。如有任何问题、意见或疑虑，请发邮件至<a
            href="mailto:nezhahuyu@126.com">nezhahuyu@126.com</a>。此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。</p>

    <p>©北京哪吒互娱科技有限公司保留一切权利。</p>
    <!-- <div class="rights-page">
服务条款
更新日期：2025年2月2日
欢迎来到龙骨动画！

龙骨动画是由北京哪吒互娱科技有限公司（下称“哪吒互娱”）提供的互联网软件服务。本服务条款（下称“服务条款”）是您与哪吒互娱之间关于您（“您”或“用户”）访问和使用龙骨动画以及哪吒互娱提供的其他服务（下称“服务”）的主要协议。您注册、登录龙骨动画和/或使用哪吒互娱提供的服务，即表示您同意接受服务条款，如您不同意该协议，请不要使用本服务。因此，敬请仔细阅读。

哪吒互娱有权不时地对服务条款做任何修改和补充，并在龙骨动画网站上公布。服务条款的修改和补充将在公布时立即生效。您继续访问和使用龙骨动画即视为您接受修订后的服务条款。如您不同意修订后的服务条款，您应停止访问龙骨动画并拒绝使用服务或删除您在龙骨动画上的信息和帐户来终止接受服务。

如果您代表某个机构而非您个人注册、登录和使用龙骨动画和/或我们其他的服务，则您将被认为获得充分授权代表该机构同意本服务条款以及不时修订和补充的服务条款。

1.服务内容
龙骨动画的具体服务由哪吒互娱根据实际情况提供，例如、个人信息分享以及在线评论、在线协作等服务。哪吒互娱为用户提供了专业易用的动画工具和海量图库资源和动画资源，支持在线创作骨骼动画，序列帧动画，图片，gif，视频，html等，同时依托于互联网实现了用户间的实时协作和共享。

为不断提升用户体验，哪吒互娱会不时为用户提供其他服务内容，届时，可能与您另行签订其他专项协议。但即使未另行签订其他专项协议，您使用龙骨动画的具体服务时，也将被视为您同意哪吒互娱关于该等具体服务的任何要求。如果其他专项协议和本条款之间存在冲突，应以其他专项协议为准，但以该冲突和与该协议特定事宜相关为限。

哪吒互娱保留随时变更、中止或终止免费服务的权利，并保留根据实际情况随时调整龙骨动画提供的服务种类、形式的权利。哪吒互娱不承担因龙骨动画提供的任何免费服务的调整给您造成的损失。尽管有本条约定，哪吒互娱有权在未来恰当的时机对该等免费服务内容收取相应的服务费用。哪吒互娱保留随时终止向您提供的收费服务的权利，并保留根据实际情况随时调整龙骨动画提供的收费服务种类和形式的权利。如果哪吒互娱终止提供某项收费服务，哪吒互娱的义务仅在于向您返还您尚未使用的服务期对应的部分费用。但无论如何，哪吒互娱将尽合理的努力为您预留合理的时间以便您为该等服务的变更、中止或终止做出应对。

2.注册
为了能访问龙骨动画和使用服务，您同意以下事项：依龙骨动画网站提示填写准确的、真实的注册手机号、邮箱、密码和名称，并确保今后更新的登录手机号、邮箱、名称、头像等资料的有效性和合法性。若您提供任何违法、虚假、不道德或哪吒互娱认为不适合在龙骨动画上展示的资料；或者哪吒互娱有理由怀疑您的资料属于病毒程序或恶意操作；或者您违反本服务条款的规定；或者未经哪吒互娱同意，将龙骨动画用于商业目的，哪吒互娱有权暂停或终止您的帐号，并拒绝您于现在和未来使用服务之全部或任何部分。

尽管有前述规定，哪吒互娱无义务对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、准确性、完整性、适用性及/或是否为最新资料的责任。同时，哪吒互娱建议您妥善保管您的注册邮箱、手机号、密码和名称，准确输入该等信息将作为您访问登录龙骨动画并享有服务的关键环节。如果您发现有人未经授权使用您的帐户信息或怀疑任何人在未经授权的情况下，能够访问您的私有内容，您应立即更改密码，并向我们反馈。如果您提供的信息不正确或您未能确保您的帐户信息的安全而造成任何损失或损害，哪吒互娱不承担任何责任。

3.会员服务
龙骨动画会员服务（“会员服务”）是您支付会员服务费后在会员有效时长内享有包括“不限文件数量”等服务在内的服务产品。您可以选择购买多种类型龙骨动画的会员服务，在依约支付一定费用后开通会员服务。会员服务开通成功后，您可在会员有效时长内享受相应会员权益。哪吒互娱会根据用户的需求、产品的变化等，对现有会员服务内容进行调整。哪吒互娱也会在不同时间推出具体不同的会员服务内容，以不断完善、优化会员服务。具体会员服务内容以【“价格”】页面公布、实际提供的内容为准。

会员服务包括短期会员及终身会员（100年）类型，短期会员指开通会员服务后在相应会员期限内，会员可以不受次数限制地使用会员服务内容，终身会员（100年）是指在开通会员服务后100年内，会员可以不受次数限制地使用会员服务内容。您享有的会员服务的服务期限以您自行选择并支付相应服务费用的期限为准，您可以登陆【“价格”】页面查询。您选择使用会员服务后，您有权随时停止使用会员服务，但哪吒互娱不以任何方式对您所支付的费用予以退还，除非哪吒互娱停止提供会员服务。

禁止通过以下方式开通或使用会员服务：

(1) 以营利、经营等非个人使用的目的为自己或他人开通会员服务；
(2) 通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通会员服务；
(3) 通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通会员服务；
(4) 通过非哪吒互娱指定的方式为自己或他人开通会员服务；
(5) 通过侵犯哪吒互娱或他人合法权益的方式为自己或他人开通会员服务；
(6) 通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通会员服务。
哪吒互娱会根据会员服务的整体规划，对会员服务的服务内容、收费标准、方式等进行修改和变更，前述修改、变更，哪吒互娱将在相应服务页面进行公示。您若需要获取、使用会员服务，请先提前了解清楚当时关于会员服务的收费标准、方式等信息。

4.内容发布以及使用权
4.1内容的发布

用户在龙骨动画上发布的内容（包含但不限于龙骨动画目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表哪吒互娱的立场或观点。作为内容的发布者，您对在本平台上传、发布的内容承担全部责任，与哪吒互娱无关。哪吒互娱无法控制经由本平台上传、发布的内容，因此不保证上传、发布的内容的正确性、合法性、安全性、完整性。尽管哪吒互娱有权对您上传至龙骨动画的数字作品进行审核，但该审核仅限于形式审核，不对您上传的内容作任何实质性审核，且哪吒互娱的上述审核行为不视为哪吒互娱对于您使用本平台所上传、发布的内容承担任何保证责任。为了保证发布模板的规范性和准确性，哪吒互娱有权对发布至模板社区的模板进行调整（比如修改标题、标签、文件描述、外观样式等）。一旦发布的模板涉嫌侵权或违法违规内容，哪吒互娱有权下架模板，并且视情节轻重对该账号采取限制发布、冻结收益、注销账号等措施。如果涉嫌侵权或违法违规行为的用户是VIP，哪吒互娱有权取消其VIP身份。如您使用本平台上传的内容违反法律规定或侵害到其他人合法权益导致任何第三人提出索赔的，均由您独立承担全部法律责任。

如若哪吒互娱有理由认为您使用本服务的行为违反法律规定或有任何第三方通知哪吒互娱您使用本服务的行为侵犯其合法权益的，哪吒互娱有权暂停或终止您使用本服务的全部或部分，即便您使用本服务的行为有可能最终未被有管辖权的司法机构认定为非法或侵犯任何第三方的合法权益。

您认为其他用户在龙骨动画上发表的内容侵犯您的合法权利，您有权向哪吒互娱提交书面通知（该书面通知应当包含下列内容：（一）您的姓名、联系方式和地址；（二）要求删除或者断开链接的内容的名称和网络地址；（三）构成侵权的初步证明材料；（四）对侵权行为的简要描述。如果您代表所属单位、机构，还请提交委托证明；该书面通知包含的每页内容您均应亲笔签名或加盖公司印章，您可以将上述书面通知的电子扫描件文档发送至哪吒互娱指定电子邮箱【nezhahuyu@126.com 】

哪吒互娱将在收到您提交的书面通知后下架您在书面通知书里要求取消公开的涉嫌侵权的内容或断开与该等涉嫌侵权内容的链接并将您的通知书的内容通过邮件的方式转送给涉嫌侵权的版权方，即为哪吒互娱履行完毕向涉嫌侵权的版权方转送该通知书的义务。

如本款上述涉嫌侵权的版权方认为哪吒互娱依据您的通知书删除或断开链接的涉嫌侵权的内容未侵犯他人权利的，其有权向哪吒互娱提交书面说明，要求恢复被下架的内容或者恢复与被断开的内容的链接，哪吒互娱将在接到其书面说明后恢复被下架的内容或者恢复与被断开的内容的链接，同时将该书面说明通过邮件的方式转送给您。哪吒互娱上述下架内容与恢复内容的行为是在用户的申请下进行的，并非哪吒互娱独立做出的相关决定，关于侵权方与被侵权方之间的纠纷，由双方独立解决，哪吒互娱作为平台方仅负有沟通、协调的义务，不承担双方之间的任何侵权责任。

用户不得使用龙骨动画服务发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：

(1) 反对宪法所确定的基本原则的；
(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
(3) 损害国家荣誉和利益的；
(4) 煽动民族仇恨、民族歧视，破坏民族团结的；
(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
(8) 侮辱或者诽谤他人，侵害他人合法权益的；
(9) 含有法律、行政法规禁止的其他内容的。
用户承诺发表言论要：爱国、守法、自律、真实、文明。不传播任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、危害国家安全的、泄露国家机密的、破坏国家宗教政策和民族团结的以及其它违反法律法规及政策的内容。若用户的行为不符合以上提到的服务条款，龙骨动画将作出独立判断立即暂停或终止用户的服务帐号。用户需对自己在网上的行为承担法律责任，龙骨动画不承担任何法律责任及连带责任。

关于付费模板

(1) 用户发布的付费模板在系统审核通过后会自动公开到龙骨动画，并供其他用户付费使用；
(2) 付费模板在龙骨动画平台上完成交易后，成交额的30%作为平台服务费（含税），其余的70%作为作者收益计入到个人账户、并可提现；
(3) 用户将付费模板或免费模板公开到龙骨动画后，授权龙骨动画在第三方平台进行模板推广。现处于推广阶段，龙骨动画不对第三方模板市场分发定价、不进行作者分成。
4.2 内容的使用权

用户将文件发布并公开到龙骨动画后，即视为永久授权龙骨动画使用，并授权哪吒互娱将文件通过互联网及/或移动互联网面向全球用户进行发布、推广以及传播，以及以任何合法方式将文件用于对龙骨动画平台的宣传、推广。若用户上传的文件涉及到个人隐私及商业秘密应在文件中予以注明，对于未注明的，视为未涉及个人隐私及商业秘密，龙骨动画可以不做任何标记直接用于宣传及推广展示使用，若因此发生纠纷，龙骨动画不承担任何法律责任。

龙骨动画平台的模板使用，无论是使用平台自有模板，还是其他用户上传的付费模板，任何时候您均不得将通过前述服务获得的有权使用的模板的任何部分或全部以转授权、分授权或其他任何方式免费的或收费的提供给他人（包括自然人及具备或不具备法人主体资格的机构、组织）使用，亦不得利用有权使用的数字作品的任何部分或全部以销售、租赁或变相销售、租赁或其他类似的方式牟利。

恶意利用哪吒互娱规则漏洞或平台系统漏洞进行任何非法活动的，哪吒互娱有权终止为其提供产品服务，并有权对其采取包括但不限于警告、限制或禁止使用其账号全部或部分功能，冻结、扣除、清零账号下的所有权益及收益、注销账号等措施。

5.版权
龙骨动画的外观设计、计算机代码与专利等均归哪吒互娱所有。未经哪吒互娱事先书面许可，您不能复制、拷贝、或者使用任何部分的代码和外观设计。

6.隐私政策
使用龙骨动画和哪吒互娱提供的服务，即表示您同意哪吒互娱合法收集和使用有关您及您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进龙骨动画产品的内容和技术，提升龙骨动画的服务品质。

哪吒互娱不会将您的信息和内容提供或出售给其他的组织或个人，但以下情况除外：

（1）您的事先同意或授权，或您于龙骨动画上主动与第三方进行分享操作；
（2）司法机关或有权政府机构或任何法律法规部门规章要求哪吒互娱提供该等信息；
（3）您违反了本服务条款，且哪吒互娱需要向第三方提供的。
7.数据安全
哪吒互娱将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。

因互联网技术本身等非哪吒互娱主观故意或重大过失导致危害您数据安全的，哪吒互娱不承担任何赔偿责任。因哪吒互娱重大过失危害您数据安全的，哪吒互娱的赔偿责任以向您收取的服务费用为上限。

8.免责声明
一旦您注册成为用户即表示您与哪吒互娱达成协议，完全接受本服务条款项下的全部条款。对免责声明的解释、修改及更新权均属于哪吒互娱所有。

（1）由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因哪吒互娱原因导致的个人信息的泄漏，哪吒互娱不承担任何法律责任；
（2）任何第三方根据哪吒互娱各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷，哪吒互娱不承担任何法律责任以及连带责任；
（3）任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭，哪吒互娱不承担任何法律责任；
（4）我们鼓励用户充分利用龙骨动画自由地发布和共享自己的信息。您可以自由发布文字、图片、动画、视频等内容，但这些内容必须位于公共领域内，或者您拥有这些内容的使用权。同时，用户不应在自己的个人主页或龙骨动画的任何其他地方发布受版权保护的内容；
（5）用户在龙骨动画发布侵犯他人知识产权或其他合法权益的内容，哪吒互娱有权予以删除，并保留移交司法机关处理的权利；
（6）用户对于自己创作并在龙骨动画上发布的合法作品依法享有著作权及其他相关权利；
（7）互联网是一个开放平台，用户将图片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。用户明确同意使用哪吒互娱服务所存在的风险将完全由用户自己承担；因用户使用哪吒互娱服务而产生的一切后果也由用户自己承担，哪吒互娱对用户不承担任何责任。
9.服务变更/中断/终止
如因系统维护或升级的需要而暂停网络服务、调整服务功能的，哪吒互娱将尽可能事先在网站上进行通告。

如发生下列任何一种情形，哪吒互娱有权单方面中断或终止向用户提供服务而无需通知用户：

（1）用户提供的个人资料不真实；
（2）用户违反本服务条款中规定的使用规则；
（3）未经哪吒互娱同意，将龙骨动画用于商业目的。
（4）禁止在未经预先书面授权的情况下销售或转卖哪吒互娱的付费服务（包括但不限于会员权益和文件扩容数）。如果发现存在未经哪吒互娱预先书面授权囤积、倒卖、转售哪吒互娱产品的行为，哪吒互娱有权追究其的法律责任。同时，哪吒互娱有权调查任何通过不正当手段获取，会员权益、文件扩容数及兑换码的行为，一经发现，哪吒互娱有权收回用户已获得的奖励、权益，情节严重的，哪吒互娱将冻结其账号或者封号，哪吒互娱有权采用以上一种或多种的方式对违规用户进行处理。
10.退款政策
龙骨动画网站所售会员服务属于虚拟产品，一经生效后不支持退款，感谢您的理解与支持。

11.注销账号
您有权通过注销账号的方式终止本协议，您可以通过邮箱【nezhahuyu@126.com】申请完成账号注销。账号一旦注销将不可恢复，请您在操作之前自行备份账号相关的所有信息和内容，账号注销后，您将无法继续使用该账号，也无法找回该账号中的任何内容或信息，除法律有明确规定外，哪吒互娱无义务向您或您指定的第三方披露您账号中的任何内容或信息。

您注销账号后，哪吒互娱仍享有下列权利：

（1）继续保存您留存于龙骨动画的各类信息，但根据适用法律的要求删除或匿名化处理您的个人信息；
（2）对于您过往的违约行为，哪吒互娱仍可依据本协议向您追究违约责任。
12.服务条款的完善和修改
哪吒互娱有权根据互联网行业变化以及有关法律、法规的颁布、修订，不时地完善和修改哪吒互娱服务条款。哪吒互娱保留随时修改服务条款的权利，用户在使用哪吒互娱的服务时，有必要对哪吒互娱最新的服务条款进行仔细阅读和重新确认，当发生相关争议时，以最新的服务条款为准。

13.特别约定
本服务条款及其下的服务受中华人民共和国法律管辖，并按之解释。

用户使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方合法权益的情形，哪吒互娱有权直接删除该等违反规定的信息，并可以暂停或终止向该用户提供服务。

若用户利用本服务从事任何违法或侵权行为，由用户自行承担全部责任，哪吒互娱不承担任何法律责任及连带责任。因此给哪吒互娱或任何第三方造成任何损失的，用户应负责全额赔偿。

用户在此特别声明并承诺，用户已充分注意本服务协议内免除或限制哪吒互娱责任的条款，用户完全知晓并理解该等条款的规定并同意接受。

14.联系我们
欢迎您对我们的服务条款提出意见。如有任何问题、意见或疑虑，请发邮件至nezhahuyu@126.com 此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。

©北京哪吒互娱科技有限公司保留一切权利。
</div> -->
</template>
<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    components: {
    }
})
</script>
<style>
.rights-page {

    h1,
    h2,
    h3 {
        color: #333;
    }

    p {
        margin: 10px 0;
    }

    ul {
        margin: 10px 0;
        padding-left: 20px;
    }

    a {
        color: #007BFF;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}
</style>
